import React, { useEffect, useState, useContext, useMemo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./missing-tuition.scss";
import { SocketContext } from "../../../../app/socket";
import { Link } from "react-router-dom";
import { iconStyle } from "../../../../utils/generalUtils";
import { BiPrinter } from "react-icons/bi";

// Month names in Greek
const monthNamesGreek = [
  "Ιανουάριος",
  "Φεβρουάριος",
  "Μάρτιος",
  "Απρίλιος",
  "Μάιος",
  "Ιούνιος",
  "Ιούλιος",
  "Αύγουστος",
  "Σεπτέμβριος",
  "Οκτώβριος",
  "Νοέμβριος",
  "Δεκέμβριος",
];

const MissingTuition = ({ studentPayments, users }) => {
  const socketContext = useContext(SocketContext);

  const [generalTuition, setGeneralTuition] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // Current month (0-based)
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Current year
  const [priceList, setPriceList] = useState([]);

  const [usersNotPaid, setUsersNotPaid] = useState([]);

  useEffect(() => {
    const cleanUpGetStudentTuition = getStudentTuition();
    const cleanUpGetPriceList = getPriceList();
    return () => {
      cleanUpGetStudentTuition();
      cleanUpGetPriceList();
    };
  }, []);

  const getPriceList = () => {
    let args = {};

    const getPriceListListener = (data) => {
      setPriceList(data);
    };

    const refreshPriceListListener = () => {
      socketContext.socket.emit("getPriceList", args);
    };

    socketContext.socket.on("priceList", getPriceListListener);
    socketContext.socket.emit("getPriceList", args);
    socketContext.socket.on("refreshPriceList", refreshPriceListListener);

    return () => {
      socketContext.socket.off("getPriceList", getPriceListListener);
      socketContext.socket.off("priceList", getPriceListListener);
      socketContext.socket.off("refreshPriceList", refreshPriceListListener);
    };
  };

  const getStudentTuition = () => {
    let args = { user_id: -1, not_archived: true };

    const getStudentTuitionListener = (data) => {
      const parsedData = data.map((item) => {
        let instalments_due = [];
        let instalments_amount = [];

        if (item.instalments_due && typeof item.instalments_due === "string") {
          try {
            instalments_due = JSON.parse(item.instalments_due) || [];
          } catch (error) {
            console.error(
              "Failed to parse instalments_due:",
              item.instalments_due,
              error
            );
          }
        }

        if (
          item.instalments_amount &&
          typeof item.instalments_amount === "string"
        ) {
          try {
            instalments_amount = JSON.parse(item.instalments_amount) || [];
          } catch (error) {
            console.error(
              "Failed to parse instalments_amount:",
              item.instalments_amount,
              error
            );
          }
        }

        return {
          ...item,
          instalments_due,
          instalments_amount,
        };
      });

      console.log("general tuition");
      console.log(parsedData);
      setGeneralTuition(parsedData);
    };

    const refreshStudentTuitionListener = () => {
      socketContext.socket.emit("getStudentTuition", args);
    };

    socketContext.socket.on("studentTuition-1", getStudentTuitionListener);
    socketContext.socket.emit("getStudentTuition", args);
    socketContext.socket.on(
      "refreshStudentTuition-1",
      refreshStudentTuitionListener
    );

    return () => {
      socketContext.socket.off("getStudentTuition", getStudentTuitionListener);
      socketContext.socket.off("studentTuition-1", getStudentTuitionListener);
      socketContext.socket.off(
        "refreshStudentTuition-1",
        refreshStudentTuitionListener
      );
    };
  };

  // Navigate to the previous month
  const handlePreviousMonth = () => {
    setPaidStudents([]); // Reset paid students
    setUnpaidStudents([]); // Reset unpaid students

    if (currentMonth === 0) {
      setCurrentMonth(11); // Wrap around to December
      setCurrentYear((prevYear) => prevYear - 1); // Move to previous year
    } else {
      setCurrentMonth((prevMonth) => prevMonth - 1);
    }
  };

  // Navigate to the next month
  const handleNextMonth = () => {
    setPaidStudents([]); // Reset paid students
    setUnpaidStudents([]); // Reset unpaid students

    if (currentMonth === 11) {
      setCurrentMonth(0); // Wrap around to January
      setCurrentYear((prevYear) => prevYear + 1); // Move to next year
    } else {
      setCurrentMonth((prevMonth) => prevMonth + 1);
    }
  };

  // Map users by user_id for quick access, excluding archived users
  const userMap = useMemo(() => {
    const map = {};
    users.forEach((user) => {
      if (user.is_archived !== 1 && user.is_archived !== true) {
        map[user.user_id] = user;
      }
    });
    return map;
  }, [users]);

  // Map priceList by price_list_id for quick access
  const priceListMap = useMemo(() => {
    const map = {};
    priceList.forEach((priceItem) => {
      map[priceItem.price_list_id] = priceItem;
    });
    return map;
  }, [priceList]);

  const [paidStudents, setPaidStudents] = useState([]);
  const [unpaidStudents, setUnpaidStudents] = useState([]);

  // Process data whenever dependencies change
  useEffect(() => {
    const paid = [];
    const unpaid = [];

    generalTuition.forEach((tuition) => {
      const {
        student_tuition_id,
        tuition_user_id, // This connects to users.user_id
        price_list_id, // Price list ID
        instalments_due,
        instalments_amount,
      } = tuition;

      if (
        !instalments_due ||
        !instalments_amount ||
        instalments_due.length === 0 ||
        instalments_amount.length === 0
      ) {
        return; // skip this tuition
      }

      // Ensure that the student exists and is not archived
      const student = userMap[tuition_user_id];
      if (!student) {
        // Student is either not found or is archived; skip this tuition
        return;
      }

      // Get the price list item
      const priceListItem = priceListMap[price_list_id];

      // Convert instalments_amount to integers
      const instalmentsAmount = instalments_amount.map((amount) =>
        parseInt(amount, 10)
      );

      // Create list of instalments with due date and amount
      const instalments = instalments_due.map((dueDateStr, index) => {
        const [dayStr, monthStr, yearStr] = dueDateStr.split("/");
        const dueDay = parseInt(dayStr, 10);
        const dueMonth = parseInt(monthStr, 10) - 1; // month is 0-based
        const dueYear = parseInt(yearStr, 10);
        const amountDue = instalmentsAmount[index];
        return {
          index,
          dueDate: new Date(dueYear, dueMonth, dueDay),
          amountDue,
          amountPaid: 0,
          isPaid: false,
        };
      });

      // Sort instalments by due date
      instalments.sort((a, b) => a.dueDate - b.dueDate);

      // Get all payments for this tuition and student
      const payments = studentPayments
        .filter((payment) => {
          return (
            payment.tuition_id === student_tuition_id &&
            payment.student_id === tuition_user_id
          );
        })
        .sort((a, b) => new Date(a.time) - new Date(b.time)); // Sort payments by time

      // Flatten payments amounts
      let paymentAmounts = payments.map((payment) =>
        parseInt(payment.amount, 10)
      );
      let paymentIndex = 0;

      // Apply payments to instalments starting from the earliest
      instalments.forEach((inst) => {
        let amountDue = inst.amountDue - inst.amountPaid;
        while (amountDue > 0 && paymentIndex < paymentAmounts.length) {
          let paymentAmount = paymentAmounts[paymentIndex];
          if (paymentAmount >= amountDue) {
            inst.amountPaid += amountDue;
            paymentAmounts[paymentIndex] -= amountDue;
            amountDue = 0;
            inst.isPaid = true;
            if (paymentAmounts[paymentIndex] === 0) {
              paymentIndex++;
            }
          } else {
            inst.amountPaid += paymentAmount;
            amountDue -= paymentAmount;
            paymentAmounts[paymentIndex] = 0;
            paymentIndex++;
          }
        }
      });

      // Now, find the instalment for the current month
      const currentInstalment = instalments.find(
        (inst) =>
          inst.dueDate.getMonth() === currentMonth &&
          inst.dueDate.getFullYear() === currentYear
      );
      if (!currentInstalment) {
        return; // No instalment due in selected month
      }

      // Calculate total overpayment
      const totalPaid = instalments.reduce(
        (sum, inst) => sum + inst.amountPaid,
        0
      );
      const totalDue = instalments.reduce(
        (sum, inst) => sum + inst.amountDue,
        0
      );
      const overpayment = totalPaid - totalDue;

      // Determine if the current instalment has been paid
      if (currentInstalment.isPaid) {
        // Student has paid
        paid.push({
          student,
          amountPaid: currentInstalment.amountPaid,
          amountDue: currentInstalment.amountDue,
          tuition: tuition,
          overpayment: overpayment > 0 ? overpayment : 0,
          priceListItem,
        });
      } else {
        // Student hasn't paid full amount
        const amountOwedCurrent =
          currentInstalment.amountDue - currentInstalment.amountPaid;
        unpaid.push({
          student,
          amountPaid: currentInstalment.amountPaid,
          amountDue: currentInstalment.amountDue,
          tuition: tuition,
          amountOwed: amountOwedCurrent,
          priceListItem,
        });
      }
    });

    setPaidStudents(paid);
    setUnpaidStudents(unpaid);
  }, [
    generalTuition,
    studentPayments,
    currentMonth,
    currentYear,
    userMap,
    priceListMap,
  ]);

  // Slider settings
  const settings = {
    dots: false, // Remove dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false, // Disable swipe to force use of buttons
    initialSlide: currentMonth,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentMonth(newIndex);
      setPaidStudents([]); // Reset paid students
      setUnpaidStudents([]); // Reset unpaid students
    },
    afterChange: (index) => {
      setCurrentMonth(index);
    },
  };

  const generateUserRows = () => {
    console.log("unpaid students");
    console.log(unpaidStudents);
    return unpaidStudents
      .map(
        ({
          student,
          amountPaid,
          amountDue,
          tuition,
          amountOwed,
          priceListItem,
        }) => {
          return `
      <tr>
        <td width="25%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
          ${student.first_name} ${student.last_name}
        </td>
        <td width="25%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
          ${priceListItem ? priceListItem.title : ""}
        </td>
        <td width="25%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
          ${amountPaid}
        </td>
        <td width="25%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
          ${amountOwed} €
        </td>
      </tr>
    `;
        }
      )
      .join("");
  };

  let htmlTemplate = `
  <!DOCTYPE html>
  <html
    xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
    lang="en"
  >
    <head>
      <title></title>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <!--[if mso
        ]><xml
          ><o:OfficeDocumentSettings
            ><o:PixelsPerInch>96</o:PixelsPerInch
            ><o:AllowPNG /></o:OfficeDocumentSettings></xml
      ><![endif]-->
      <!--[if !mso]><!-->
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900"
        rel="stylesheet"
        type="text/css"
      />
      <!--<![endif]-->
      <style>
        * {
          box-sizing: border-box;
        }
  
        body {
          margin: 0;
          padding: 0;
        }
  
        a[x-apple-data-detectors] {
          color: inherit !important;
          text-decoration: inherit !important;
        }
  
        #MessageViewBody a {
          color: inherit;
          text-decoration: none;
        }
  
        p {
          line-height: inherit;
        }
  
        .desktop_hide,
        .desktop_hide table {
          mso-hide: all;
          display: none;
          max-height: 0px;
          overflow: hidden;
        }
  
        .image_block img + div {
          display: none;
        }
  
        sup,
        sub {
          line-height: 0;
          font-size: 75%;
        }
  
        @media (max-width: 768px) {
          .desktop_hide table.icons-inner {
            display: inline-block !important;
          }
  
          .icons-inner {
            text-align: center;
          }
  
          .icons-inner td {
            margin: 0 auto;
          }
  
          .mobile_hide {
            display: none;
          }
  
          .row-content {
            width: 100% !important;
          }
  
          .stack .column {
            width: 100%;
            display: block;
          }
  
          .mobile_hide {
            min-height: 0;
            max-height: 0;
            max-width: 0;
            overflow: hidden;
            font-size: 0px;
          }
  
          .desktop_hide,
          .desktop_hide table {
            display: table !important;
            max-height: none !important;
          }
        }
      </style>
      <!--[if mso
        ]><style>
          sup,
          sub {
            font-size: 100% !important;
          }
          sup {
            mso-text-raise: 10%;
          }
          sub {
            mso-text-raise: -10%;
          }
        </style>
      <![endif]-->
    </head>
  
    <body
      class="body"
      style="
        background-color: #ffffff;
        margin: 0;
        padding: 0;
        -webkit-text-size-adjust: none;
        text-size-adjust: none;
      "
    >
      <table
        class="nl-container"
        width="100%"
        border="0"
        cellpadding="0"
        cellspacing="0"
        role="presentation"
        style="
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          background-color: #ffffff;
        "
      >
        <tbody>
          <tr>
            <td>
            <table
                      class="row-content stack"
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-radius: 0;
                        color: #000000;
                        width: 900px;
                        margin: 0 auto;
                      "
                      width="900"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column column-1"
                            width="75%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              padding-bottom: 5px;
                              padding-top: 5px;
                              vertical-align: middle;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                          >
                            <table
                              class="heading_block block-1"
                              width="100%"
                              border="0"
                              cellpadding="10"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                              "
                            >
                              <tr>
                                <td class="pad">
                                  <h1
                                    style="
                                      margin: 0;
                                      color: #7747ff;
                                      direction: ltr;
                                      font-family: 'Montserrat', 'Trebuchet MS',
                                        'Lucida Grande', 'Lucida Sans Unicode',
                                        'Lucida Sans', Tahoma, sans-serif;
                                      font-size: 38px;
                                      font-weight: 700;
                                      letter-spacing: normal;
                                      line-height: 120%;
                                      text-align: center;
                                      margin-top: 0;
                                      margin-bottom: 0;
                                      mso-line-height-alt: 45.6px;
                                    "
                                  >
                                  ${
                                    window.location.hostname.includes(
                                      "oramapaideias"
                                    )
                                      ? `<img src="https://oramapaideias.edupal.gr/resources/logos/orama_paideias.png" style="display: block; height: auto; border: 0; max-width: 135px; width: 100%;" width="135" alt="Logo" title="Logo">`
                                      : `<img src="https://md.edupal.gr/resources/logos/edupal.png" style="display: block; height: auto; border: 0; max-width: 135px; width: 100%;" width="135" alt="Logo" title="Logo">`
                                  }
                                  </h1>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td
                            class="column column-2"
                            width="25%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              padding-bottom: 5px;
                              padding-top: 5px;
                              vertical-align: middle;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                          >
                            <table
                              class="paragraph_block block-1"
                              width="100%"
                              border="0"
                              cellpadding="10"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                word-break: break-word;
                              "
                            >
                              <tr>
                                <td class="pad">
                                  <div
                                    style="
                                      color: #101112;
                                      direction: ltr;
                                      font-family: 'Montserrat', 'Trebuchet MS',
                                        'Lucida Grande', 'Lucida Sans Unicode',
                                        'Lucida Sans', Tahoma, sans-serif;
                                      font-size: 16px;
                                      font-weight: 400;
                                      letter-spacing: 0px;
                                      line-height: 120%;
                                      text-align: center;
                                      mso-line-height-alt: 19.2px;
                                    "
                                  >
                                    <p style="margin: 0; margin-bottom: 16px">
                                      <strong>Χρωστούμενα Δίδακτρα</strong>
                                    </p>
                                    <p style="margin: 0">
                                      <strong>#weekPeriod</strong>
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
              <table
                class="row row-2"
                align="center"
                width="100%"
                border="0"
                cellpadding="0"
                cellspacing="0"
                role="presentation"
                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        class="row-content stack"
                        align="center"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-radius: 0;
                          color: #000000;
                          width: 900px;
                          margin: 0 auto;
                        "
                        width="900"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="column column-1"
                              width="100%"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                font-weight: 400;
                                text-align: left;
                                padding-bottom: 5px;
                                padding-top: 5px;
                                vertical-align: top;
                                border-top: 0px;
                                border-right: 0px;
                                border-bottom: 0px;
                                border-left: 0px;
                              "
                            >
                              <table
                                class="table_block block-1"
                                width="100%"
                                border="0"
                                cellpadding="10"
                                cellspacing="0"
                                role="presentation"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                "
                              >
                                <tr>
                                  <td class="pad">
                                    <table
                                      style="
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                        border-collapse: collapse;
                                        width: 100%;
                                        table-layout: fixed;
                                        direction: ltr;
                                        background-color: transparent;
                                        font-family: 'Montserrat', 'Trebuchet MS',
                                          'Lucida Grande', 'Lucida Sans Unicode',
                                          'Lucida Sans', Tahoma, sans-serif;
                                        font-weight: 400;
                                        color: #101112;
                                        text-align: center;
                                        letter-spacing: 0px;
                                        word-break: break-all;
                                      "
                                      width="100%"
                                    >
                                      <thead
                                        style="
                                          vertical-align: top;
                                          background-color: #f2f2f2;
                                          color: #101112;
                                          font-size: 17px;
                                          line-height: 120%;
                                          text-align: center;
                                        "
                                      >
                                        <tr>
                                          <th
                                            width="16.666666666666668%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΜΑΘΗΤΗΣ/ΤΡΙΑ<br />
                                          </th>
                                          <th
                                            width="16.666666666666668%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΔΙΔΑΚΤΡΑ
                                          </th>
                                          <th
                                            width="16.666666666666668%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                          ΠΛΗΡΩΤΕΟ ΠΟΣΟ
                                          </th>
                                          <th
                                            width="16.666666666666668%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΧΡΩΣΤΟΎΜΕΝΟ ΠΟΣΌ
                                          </th>
                                        </tr>
                                      </thead>
                                      ${generateUserRows()}
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- End -->
    </body>
  </html>  
  `;

  const handlePrint = () => {
    const headerText = monthNamesGreek[currentMonth] + " " + currentYear;
    // Replace the placeholders in the htmlTemplate with the calculated amounts and headerText for #weekPeriod
    const htmlContent = htmlTemplate.replace(/#weekPeriod/g, headerText); // Replace #weekPeriod with headerText

    // Create a hidden iframe for printing
    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.style.position = "absolute";
    iframe.style.width = "0px";
    iframe.style.height = "0px";
    iframe.style.border = "none";

    iframe.onload = () => {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
      setTimeout(() => {
        iframe.remove();
      }, 1000);
    };

    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(htmlContent);
    doc.close();
  };

  return (
    <div className="missing-tuition">
      {/* Custom Header */}
      <div className="slider-header">
        <button className="cta cta-shadow" onClick={handlePreviousMonth}>
          Προηγούμενος
        </button>
        <span className="slider-header__title">
          {monthNamesGreek[currentMonth]} {currentYear}
        </span>
        <button className="cta cta-shadow" onClick={handleNextMonth}>
          Επόμενος
        </button>
      </div>

      {/* Slider */}
      <Slider {...settings}>
        {monthNamesGreek.map((month, index) => (
          <div key={index}>{/* Slide content if needed */}</div>
        ))}
      </Slider>
      <div className="student-lists">
        <div className="missing-tuition__users-list">
          <span className="list-label">Μαθητές που έχουν πληρώσει</span>
          {paidStudents.map(
            ({
              student,
              amountPaid,
              amountDue,
              tuition,
              overpayment,
              priceListItem,
            }) => (
              <Link
                className="list-user-item"
                to={"/profile?user-id=" + student.user_id}
                key={"index user " + student.user_id}
              >
                <div className="personal-info">
                  <div className="personal-info__img">
                    <img
                      src={
                        student.profile_picture
                          ? student.profile_picture
                          : "resources/student.png"
                      }
                      alt={"student"}
                    />
                  </div>
                </div>
                <div className="details">
                  <div className="details__name">
                    <span>{student.first_name}</span>
                    <span>{student.last_name}</span>
                  </div>
                  {priceListItem && (
                    <div className="details__price-list">
                      <span>{priceListItem.title}</span>
                    </div>
                  )}
                  <div className="details__paid">
                    <span>
                      Πληρωμένο ποσό:{" "}
                      <span className="details__paid-bold">
                        {amountPaid}€ / {amountDue}€{" "}
                        {overpayment > 0
                          ? ` (Υπερπληρωμή ${overpayment}€)`
                          : ""}
                      </span>
                    </span>
                  </div>
                </div>
              </Link>
            )
          )}
        </div>
        <div className="missing-tuition__users-list">
          <span className="list-label">
            Μαθητές που δεν έχουν πληρώσει
            <BiPrinter
              className="options__print"
              size={"28px"}
              color={"rgb(204, 204, 204)"}
              style={iconStyle("transparent")}
              onClick={() => handlePrint()}
            />
          </span>
          {unpaidStudents.map(
            ({
              student,
              amountPaid,
              amountDue,
              tuition,
              amountOwed,
              priceListItem,
            }) => (
              <Link
                className="list-user-item"
                to={"/profile?user-id=" + student.user_id}
                key={"index user " + student.user_id}
              >
                <div className="personal-info">
                  <div className="personal-info__img">
                    <img
                      src={
                        student.profile_picture
                          ? student.profile_picture
                          : "resources/student.png"
                      }
                      alt={"student"}
                    />
                  </div>
                </div>
                <div className="details">
                  <div className="details__name">
                    <span>{student.first_name}</span>
                    <span>{student.last_name}</span>
                  </div>
                  {priceListItem && (
                    <div className="details__price-list">
                      <span>{priceListItem.title}</span>
                    </div>
                  )}
                  <div className="details__paid">
                    <span>
                      Πληρωμένο ποσό:{" "}
                      <span className="details__paid-bold">
                        {amountPaid}€ / {amountDue}€
                        {amountOwed > 0 ? ` (Οφείλει ${amountOwed}€)` : ""}
                      </span>
                    </span>
                  </div>
                </div>
              </Link>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default MissingTuition;
